<script>
import EzPopupContainer from '@dmant/ez-ui-components/src/components/popup-container';
import { hide } from '@/services/modal';
import NAME from './name.json';

export default {
  name: 'EzfChangeGroup',
  components: { EzPopupContainer },
  $_veeValidate: {
    validator: 'new',
  },
  inject: ['$validator'],
  props: {
    id: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      gName: '',
      gColor: '',
    };
  },
  created() {
    this.gName = this.name;
    this.gColor = this.color;
  },
  computed: {
    title() {
      return this.id ? this.$t('groups.dialog.editTitle') : this.$t('groups.dialog.createTitle');
    },
    icon() {
      return this.id ? 'pen' : 'plus';
    },
  },
  methods: {
    onCancel() {
      hide(NAME);
    },
    async onAccept() {
      const isValid = await this.$validator.validate();
      if (isValid) {
        const payload = {
          name: this.gName,
          color: this.gColor,
        };
        hide(NAME, payload);
      }
    },
  },
};
</script>

<template>
  <ez-popup-container
    service-name="funnels"
    :icon-header="icon"
    @close="onCancel"
  >
    <template slot="header">
      {{ title }}
    </template>

    <form @submit.prevent="onAccept">
      <ez-form-item
        :label="$t('groups.dialog.name.label')"
        :type="errors.has('name') ? 'error' : null"
        :message="errors.first('name')"
      >
        <ez-input
          name="name"
          v-model="gName"
          v-validate="'required|min:2'"
          :placeholder="$t('groups.dialog.name.placeholder')"
          data-test="group-input-name"
        ></ez-input>
      </ez-form-item>
    </form>

    <ez-form-item
      class="mt-3"
      :label="$t('groups.dialog.color.label')"
    >
      <ez-color-picker v-model="gColor" />
    </ez-form-item>

    <template slot="footer">
      <ez-button-group justify="end">
        <ez-button type="secondary" @click="onCancel">
          {{ $t("builder.buttons.discard") }}
        </ez-button>
        <ez-button
          data-test="group-button-submit"
          type="success"
          @click="onAccept"
          :disabled="errors.any()"
        >
          {{ $t("builder.buttons.saveChanges") }}
        </ez-button>
      </ez-button-group>
    </template>
  </ez-popup-container>
</template>
